import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import * as yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import ErrorMessage from '../../../../../../../components/FormElements/ErrorMessage';
import { Label, TextAreaInput, TextInput } from '../../../../../../../components/FormElements';
import Dialog from '../../../../../../../components/Dialog';
import { FormActionButton } from '../../../../common/FormControls';
import { Toast } from '../../../../../../../components/Toast';
import { toast } from 'react-hot-toast';
import {
  ModalType,
  ModuleCreateMutationType,
  ModuleUpdateMutationType,
  ClosedCaptionsType,
  ModuleCreateInMultiLangMutationType,
} from '../../../../../types';
import { useParams } from 'react-router-dom';
import {
  useGetSpeakersQuery,
  useModuleUpdateMutation,
  useModuleCreateMutation,
  useMediaUploadManager,
  useClosedCaptionsUploadManager,
  useModuleCreateInMultiLangMutation,
} from '../../../../../hooks';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { fieldHasError } from '../../../../../../../utils/react-hook-form';
import { Uploader, UploaderInit } from '../../../../../../../api/s3/multipart.helper';
import { finalizeMultipartUpload, getPreSignedUrlCourse } from '../../../../../../../api';
import {
  MEDIA_SIZE_LIMIT,
  MEDIA_TYPE_PREFIX,
  MODULE_ASSESSMENT_ACTION,
  MODULE_TYPE,
  MODULE_TYPE_MESSAGE,
} from '../../../../../constants';
import MediaUploadDropZone from '../../../../../../../components/DropZone/MediaUploadDropZone';
import useUploadImageQuery from '../../../../../../shared/hooks/useUploadImageQuery';
import { UPLOAD_REQUEST_TYPE } from '../../../../../../shared/constants/imageUpload';
import { validateMediaFile, validateMediaUrl } from '../../../../../../shared/helpers/media.helper';
import { useStrapiHQAdminCoursesData } from '../../../../../hooks/useStrapiHQAdminCourseData';
import { showToast } from '../../../../../../shared/helpers/toast';
import ClosedCaptionsTable from '../../ClosedCaptionsTable';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../shared/helpers';
import { COURSES_ERROR_CODE } from '../../../../../constants/courses-management';
import { customStylesOne } from '../../../../../../../styles/modalCustomStyle';
import cn from '../../../../../../../utils/cn';
import useDownloadPreSignedQuery from '../../../../../../courses/hooks/useDownloadPreSignedQuery';

interface ModuleAssessmentFormProps {
  setIsModuleModalOpen: (open: boolean) => void;
  modulesAssessmentPrefetchData?: Record<string, any>;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  language: string;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  setActionInProgress: (a: keyof typeof MODULE_ASSESSMENT_ACTION | null) => void;
}

interface Schema {
  nameRequired: string;
  nameMinValue: number;
  nameMinError: string;
  nameMaxValue: number;
  nameMaxError: string;
  descriptionRequired: string;
  descriptionMinValue: number;
  descriptionMinError: string;
  descriptionMaxValue: number;
  descriptionMaxError: string;
  moduleTypeRequired: string;
  speakerRequired: string;
  speakerMinValue: number;
  speakerMinError: string;
  speakerMaxValue: number;
  speakerMaxError: string;
  durationRequired: string;
  durationMinValue: number;
  durationMinError: string;
  thumbnailRequired: string;
  mediaRequired: string;
  closedCaptionRequired: string;
}
const getSchema = (data: Schema) => {
  const {
    nameRequired,
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    descriptionRequired,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
    moduleTypeRequired,
    speakerRequired,
    speakerMinValue,
    speakerMinError,
    speakerMaxValue,
    speakerMaxError,
    durationRequired,
    durationMinValue,
    durationMinError,
    thumbnailRequired,
    mediaRequired,
  } = data;
  const moduleFormSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(nameMinValue, nameMinError)
      .max(nameMaxValue, nameMaxError)
      .required(nameRequired)
      .label('name'),
    description: yup
      .string()
      .trim()
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .required(descriptionRequired)
      .label('description'),
    speaker: yup
      .array()
      .min(speakerMinValue, speakerMinError)
      .max(speakerMaxValue, speakerMaxError)
      .required(speakerRequired)
      .label('speaker'),
    moduleType: yup.string().required(moduleTypeRequired).label('moduleType'),
    moduleDuration: yup
      .number()
      .when('moduleType', (moduleType, schema) => {
        if (moduleType === MODULE_TYPE.DOCUMENT) {
          return schema
            .typeError(durationRequired)
            .min(durationMinValue, durationMinError)
            .required(durationRequired);
        } else {
          return schema.nullable().notRequired();
        }
      })
      .label('moduleDuration'),
    mediaUrl: yup.string().required(mediaRequired).label('mediaUrl'),
    thumbnailUrl: yup.string().required(thumbnailRequired).label('thumbnailUrl'),
    closedCaptionsUrls: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          url: yup.string(),
          FileName: yup.string(),
          language: yup.string(),
        })
      ),
  });
  return moduleFormSchema;
};

const ModuleAssessmentForm = ({
  setIsModuleModalOpen,
  modulesAssessmentPrefetchData,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  refetch,
  language,
  errorType,
  setErrorType,
  setActionInProgress,
}: ModuleAssessmentFormProps) => {
  const moduleType = [
    { label: 'Video', value: 'VIDEO' },
    { label: 'Audio', value: 'AUDIO' },
    { label: 'Document', value: 'DOCUMENT' },
  ];

  const { id } = useParams();
  const speakersFetch = useGetSpeakersQuery();
  const { moduleModal } = useStrapiHQAdminCoursesData();
  const {
    nameLabel,
    namePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    moduleTypeLabel,
    moduleTypePlaceholder,
    speakerLabel,
    speakerPlaceholder,
    durationLabel,
    durationPlaceholder,
    thumbnailType,
    thumbnailLabel,
    thumbnailMaxValue,
    closedCaptionsLabel,
    closedCaptionsMaxValue,
    closedCaptionsType,
    closedCaptionsRequired,
    closedCaptionsUploadLimitError,
    LanguageOptions,
    mediaLabel,
    cancelButton,
  } = moduleModal;
  const moduleFormSchema = getSchema(moduleModal);
  type MODULE_FORM_DATA = yup.InferType<typeof moduleFormSchema>;
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    trigger,
    clearErrors,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<MODULE_FORM_DATA>({
    mode: 'onSubmit',
    resolver: yupResolver(moduleFormSchema),
  });

  const [mediaMessage, setMediaMessage] = useState<{ label: string; size: string }>(
    MODULE_TYPE_MESSAGE.DEFAULT
  );

  // State to hold the current moduleType
  const [currentModuleType, setCurrentModuleType] = useState<string | null>(
    modulesAssessmentPrefetchData?.data?.type || null
  );

  const [mediaUrlKey, setMediaUrlKey] = useState<string | null>(null);

  // State to hold the module media
  const [currentMedia, setCurrentMedia] = useState(null);

  // Fetch the signed URL
  const downloadMediaQuery = useDownloadPreSignedQuery({
    key: mediaUrlKey,
  });

  useEffect(() => {
    if (downloadMediaQuery.data) {
      setCurrentMedia(downloadMediaQuery.data.url);
    }
  }, [downloadMediaQuery.data]);

  const [moduleDurationVisible, setModuleDurationVisible] = useState<boolean>(false);

  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  //  Mutation hooks
  const createModuleMutation = useModuleCreateMutation();
  const createModuleInMultiLangMutation = useModuleCreateInMultiLangMutation();
  const updateModuleMutation = useModuleUpdateMutation();
  const {
    toastMessages: {
      createModuleSuccessTitle,
      createModuleFailureTitle,
      updateModuleSuccessTitle,
      updateModuleFailureTitle,
      createModuleSuccessMessage,
      createModuleFailureMessage,
      updateModuleFailureMessage,
      updateModuleSuccessMessage,
    },
  } = useStrapiHQAdminCoursesData();

  //  Media upload
  const [mediaUploader, setMediaUploader] = useState<any>(undefined);

  const { isProgress, progress, isUploaded, data, initiating, aborted } = useMediaUploadManager({
    uploader: mediaUploader,
  });

  // State to manage isMediaUploaded
  const [isMediaUploaded, setIsMediaUploaded] = useState(isUploaded);

  const [closedCaptionUploader, setClosedCaptionUploader] = useState<any>(undefined);
  const [closedCaptions, setClosedCaptions] = useState<ClosedCaptionsType[]>([]);

  const {
    uploadState: {
      isProgress: isClosedCaptionProgress,
      progress: closedCaptionProgress,
      isUploaded: isClosedCaptionUploaded,
      data: closedCaptionData,
      initiating: closedCaptionInitiating,
      aborted: closedCaptionAborted,
    },
    resetUploadState,
  } = useClosedCaptionsUploadManager({
    uploader: closedCaptionUploader,
  });

  // State to manage ClosedCaptionUploaded
  const [ClosedCaptionUploaded, setClosedCaptionUploaded] = useState(isUploaded);

  const validateMediaForModuleType = useCallback(
    (mediaUrl: string, moduleType: string) => {
      if (mediaUrl && mediaUrl !== '') {
        const { error, message } = validateMediaUrl(
          mediaUrl,
          MEDIA_TYPE_PREFIX[moduleType],
          'Media'
        );
        console.log('error', error);
        if (error) {
          setValue('mediaUrl', '');
          setCurrentMedia(null);
          setError('mediaUrl', { message });
        } else {
          setValue('mediaUrl', mediaUrl || '', {
            shouldValidate: true,
          });
          setMediaUrlKey(mediaUrl);
        }
      }
    },
    [setValue, setCurrentMedia, setError]
  );

  // useEffect hook that deal with form data
  useEffect(() => {
    const setCreateModuleValues = () => {
      if (modulesAssessmentPrefetchData?.data) {
        setValue(
          'speaker',
          modulesAssessmentPrefetchData.data.speakers.map((speaker: any) => ({
            label: speaker.name,
            value: speaker.id,
          })),
          { shouldValidate: true }
        );
        setValue('moduleType', modulesAssessmentPrefetchData.data.type);
        setValue('moduleDuration', modulesAssessmentPrefetchData.data.timeLimit);
      }
    };

    const setEditModuleValues = () => {
      if (modulesAssessmentPrefetchData?.data) {
        setValue(
          'name',
          modulesAssessmentPrefetchData.data.title || modulesAssessmentPrefetchData?.data.name
        );
        setValue('description', modulesAssessmentPrefetchData.data.description || '');
        setValue(
          'speaker',
          modulesAssessmentPrefetchData.data.speakers.map((speaker: any) => ({
            label: speaker.name,
            value: speaker.id,
          })) || []
        );
        setValue('moduleType', modulesAssessmentPrefetchData.data.type);
        validateMediaForModuleType(
          modulesAssessmentPrefetchData.data.previewUrl,
          modulesAssessmentPrefetchData.data.type
        );
        setValue('thumbnailUrl', modulesAssessmentPrefetchData.data.thumbnailUrl || '');
        setValue('closedCaptionsUrls', modulesAssessmentPrefetchData.data.closedCaptionsUrls || []);
        // Update the states with the data from API response
        setClosedCaptions(modulesAssessmentPrefetchData.data.closedCaptionsUrls || []);
        setValue('moduleDuration', modulesAssessmentPrefetchData.data.timeLimit || null);
      }
    };

    reset();
    if (modulesAssessmentPrefetchData?.data) {
      if (modalType === 'manage' && errorType === COURSES_ERROR_CODE.MODULE_ID_NOT_FOUND) {
        // Handle Create Mode
        setIsFieldDisabled(true); // Disable fields in create mode
        setCreateModuleValues(); // Prepopulate fields in create mode
      } else if (modalType === 'manage' || modalType === 'update') {
        setIsFieldDisabled(modalType === 'manage'); // Disable fields in manage mode, enable in update mode
        setEditModuleValues(); // Set all values for manage or update mode
      }

      setCurrentModuleType(modulesAssessmentPrefetchData.data.type);
      setModuleDurationVisible(modulesAssessmentPrefetchData.data.type === MODULE_TYPE.DOCUMENT);
    }
  }, [
    modulesAssessmentPrefetchData?.data,
    errorType,
    modalType,
    setValue,
    reset,
    validateMediaForModuleType,
  ]);

  // watch ModuleType
  const module = watch('moduleType');
  useEffect(() => {
    if (module) {
      // Set the media message based on the selected module type
      setMediaMessage(MODULE_TYPE_MESSAGE[module]);
      if (currentModuleType === null) {
        // In create mode, set currentModuleType when the first module type is selected
        setCurrentModuleType(module);
      } else if (module !== currentModuleType) {
        // Only reset if media has already been uploaded
        if (currentMedia) {
          setValue('mediaUrl', ''); // Reset media URL field
          trigger('mediaUrl');
          setCurrentMedia(null); // Reset media
          setIsMediaUploaded(false);
          setValue('closedCaptionsUrls', []); // Reset closed captions URLs
          setClosedCaptionUploaded(false); // Reset the closed caption uploaded state
        }

        // Update the current ModuleType
        setCurrentModuleType(module);
      }

      // Toggle visibility based on moduleType
      setModuleDurationVisible(module === MODULE_TYPE.DOCUMENT);
    }
  }, [module, currentModuleType, setValue, trigger, currentMedia]);

  const onCancel = () => {
    if (mediaUploader) {
      mediaUploader.abort();
    }
  };

  const onCancelClosedcaption = () => {
    if (closedCaptionUploader) {
      closedCaptionUploader.abort();
    }
  };

  const onDropMedia = useCallback(
    (acceptedFiles: any) => {
      const selectedType = getValues('moduleType');
      const { error, message, type } = validateMediaFile(
        acceptedFiles,
        MEDIA_SIZE_LIMIT.MODULE_CONTENT,
        MEDIA_TYPE_PREFIX[selectedType],
        'Media'
      );
      if (error) {
        // if the media url already exits show toaster else set a form error
        if (getValues('mediaUrl')) {
          showToast({
            variant: 'error',
            title: message,
          });
        } else {
          setError('mediaUrl', { message });
        }
      } else {
        clearErrors('mediaUrl');
        if (id) {
          const fileDuration = new Audio();
          fileDuration.src = URL.createObjectURL(acceptedFiles[0]);
          fileDuration.onloadedmetadata = (_) => {
            setValue('moduleDuration', Math.ceil(fileDuration.duration / 60));
          };
          const videoUploaderOptions: UploaderInit = {
            file: acceptedFiles[0],
            fileExtension: type,
            finalizeMultipartUpload: finalizeMultipartUpload,
            preSignedUrlFetch: getPreSignedUrlCourse,
            module: 'module',
            courseId: id,
            fileType: acceptedFiles[0].type,
          };
          const uploader = new Uploader(videoUploaderOptions, {});
          setMediaUploader(uploader);
        }
      }
    },
    [clearErrors, setError, getValues, id, setValue]
  );

  const onDropclosedCaption = useCallback(
    (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(
        acceptedFiles,
        MEDIA_SIZE_LIMIT.MODULE_VTT,
        MEDIA_TYPE_PREFIX['VTT'],
        'Closed captioning'
      );
      const UploadLimt = LanguageOptions.length - 1;
      if (closedCaptions.length >= UploadLimt) {
        showToast({
          variant: 'error',
          title: closedCaptionsUploadLimitError,
        });
      } else {
        if (error) {
          // if the closedCaptionsUrls url already exits show toaster else set a form error
          if (getValues('closedCaptionsUrls')) {
            showToast({
              variant: 'error',
              title: message,
            });
          } else {
            setError('closedCaptionsUrls', { message });
          }
        } else {
          clearErrors('closedCaptionsUrls');
          if (id) {
            const fileDuration = new Audio();
            fileDuration.src = URL.createObjectURL(acceptedFiles[0]);
            fileDuration.onloadedmetadata = (_) => {
              setValue('moduleDuration', Math.ceil(fileDuration.duration / 60));
            };

            const videoUploaderOptions: UploaderInit = {
              file: acceptedFiles[0],
              fileExtension: type,
              finalizeMultipartUpload: finalizeMultipartUpload,
              preSignedUrlFetch: getPreSignedUrlCourse,
              module: 'module',
              courseId: id,
              fileType: MEDIA_TYPE_PREFIX['VTT'],
              fileName: acceptedFiles[0].name,
            };

            const uploader = new Uploader(videoUploaderOptions, {});
            setClosedCaptionUploader(uploader);
          }
        }
      }
    },
    [
      closedCaptions,
      setError,
      clearErrors,
      id,
      closedCaptionsUploadLimitError,
      getValues,
      setValue,
      LanguageOptions.length,
    ]
  );

  useEffect(() => {
    if (data) {
      setValue('mediaUrl', data.key);
      trigger('mediaUrl');
      setMediaUrlKey(data.key);
    }
  }, [data, setValue, trigger]);

  useEffect(() => {
    if (closedCaptionData) {
      setClosedCaptions((prevState) => [
        ...prevState,
        {
          FileName: closedCaptionData.fileName,
          language: '',
          url: closedCaptionData.key,
        },
      ]);

      setValue('closedCaptionsUrls', closedCaptionData.key);
      trigger('closedCaptionsUrls');
      resetUploadState();
    }
  }, [closedCaptionData, setValue, trigger, resetUploadState]);

  const onThumbnailUploadCancel = () => {
    if (thumbnailUploader) {
      thumbnailAbort.abort();
    }
  };

  //  Thumbnail upload
  const [thumbnailUploader, setThumbnailUploader] = useState<any>(undefined);
  const {
    isProgress: thumbnailProgress,
    progress: thumbnailProgressPercentage,
    initiating: thumbnailInitiating,
    isUploaded: thumbnailUploaded,
    data: thumbnailData,
    abort: thumbnailAbort,
  } = useUploadImageQuery({ upload: thumbnailUploader, type: 'course' });

  // State to manage isThumbnailUploaded
  const [isThumbnailUploaded, setIsThumbnailUploaded] = useState(thumbnailUploaded);

  const onDropThumbnail = useCallback(
    (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(
        acceptedFiles,
        MEDIA_SIZE_LIMIT.MODULE_THUMBNAIL,
        'image',
        'Thumbnail'
      );
      if (error) {
        setError('thumbnailUrl', { message });
      } else {
        clearErrors('thumbnailUrl');
        if (id) {
          const uploader = {
            fileType: type,
            requestType: UPLOAD_REQUEST_TYPE.SINGLE_OBJECT,
            file: acceptedFiles[0],
            module: 'module',
            fileExtension: acceptedFiles[0].type,
            courseId: id,
          };
          setThumbnailUploader(uploader);
        }
      }
    },
    [setError, clearErrors, id]
  );

  useEffect(() => {
    if (thumbnailData) {
      setValue('thumbnailUrl', thumbnailData);
      trigger('thumbnailUrl');
    }
  }, [thumbnailData, setValue, trigger]);

  useEffect(() => {
    setIsMediaUploaded(isUploaded);
  }, [isUploaded]);

  useEffect(() => {
    setClosedCaptionUploaded(isClosedCaptionUploaded);
  }, [isClosedCaptionUploaded]);

  useEffect(() => {
    setIsThumbnailUploaded(thumbnailUploaded);
  }, [thumbnailUploaded]);

  useEffect(() => {
    // Reset upload state for closed captions if needed
    if (ClosedCaptionUploaded) {
      resetUploadState();
    }
  }, [ClosedCaptionUploaded, resetUploadState]);

  useEffect(() => {
    const errors: number[] = [];
    const filenames: string[] = [];

    closedCaptions.forEach((caption, index) => {
      if (!caption.language || caption.language.trim() === '') {
        errors.push(index);
        filenames.push(caption.FileName);
      }
    });

    if (errors.length > 0) {
      setError('closedCaptionsUrls', {
        message: ` ${closedCaptionsRequired} ${filenames.join(', ')}`,
      });
    } else {
      clearErrors('closedCaptionsUrls');
    }
  }, [clearErrors, setError, closedCaptions, closedCaptionsRequired]);

  const createModuleHandler = (data: any) => {
    if (id) {
      const createModule: ModuleCreateMutationType = {
        moduleType: data.moduleType,
        description: data.description,
        speakers: data.speaker.map((s: any) => s.value),
        moduleName: data.name,
        courseId: parseInt(id),
        mediaUrl: data.mediaUrl,
        thumbnailUrl: data.thumbnailUrl,
        closedCaptionsUrls: closedCaptions,
        timeLimit: data.moduleDuration,
      };

      setActionInProgress('create');
      createModuleMutation.mutate(createModule, {
        onSuccess: () => {
          setIsModuleModalOpen(false);
          refetch();
          toast.custom((t) => {
            return (
              <Toast
                variant="success"
                Title={createModuleSuccessTitle}
                SubTitle={createModuleSuccessMessage}
                toastInstance={t}
              />
            );
          });
        },

        onError: (error) => {
          console.error('Error while creating module %o', error);
          toast.custom((t) => (
            <Toast
              variant="error"
              Title={createModuleFailureTitle}
              SubTitle={createModuleFailureMessage}
              toastInstance={t}
            />
          ));
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
  };

  const createModuleInMultiLangHandler = (data: any) => {
    if (id && modulesAssessmentPrefetchData?.data) {
      const createModule: ModuleCreateInMultiLangMutationType = {
        courseModuleId: modulesAssessmentPrefetchData.data.id,
        moduleType: data.moduleType,
        description: data.description,
        speakers: data.speaker.map((s: any) => s.value),
        moduleName: data.name,
        courseId: parseInt(id),
        mediaUrl: data.mediaUrl,
        thumbnailUrl: data.thumbnailUrl,
        closedCaptionsUrls: closedCaptions,
        timeLimit: data.moduleDuration,
        language: language,
      };
      setActionInProgress('create');
      createModuleInMultiLangMutation.mutate(createModule, {
        onSuccess: () => {
          setIsModuleModalOpen(false);
          refetch();
          setErrorType && setErrorType(null);
          toast.custom((t) => {
            return (
              <Toast
                variant="success"
                Title={createModuleSuccessTitle}
                SubTitle={createModuleSuccessMessage}
                toastInstance={t}
              />
            );
          });
        },

        onError: (error) => {
          console.error('Error while creating module %o', error);
          toast.custom((t) => (
            <Toast
              variant="error"
              Title={createModuleFailureTitle}
              SubTitle={createModuleFailureMessage}
              toastInstance={t}
            />
          ));
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
  };

  const updateModuleHandler = (data: any) => {
    if (modulesAssessmentPrefetchData?.data) {
      const updateModule: ModuleUpdateMutationType = {
        moduleId: modulesAssessmentPrefetchData.data.id,
        moduleName: data.name,
        description: data.description,
        speakers: data.speaker.map((speaker: any) => speaker.value),
        thumbnailUrl: data.thumbnailUrl,
        moduleType: data.moduleType,
        closedCaptionsUrls: closedCaptions,
        mediaUrl: data.mediaUrl,
        timeLimit: data.moduleDuration,
        language: language,
      };

      setActionInProgress('update');
      updateModuleMutation.mutate(updateModule, {
        onSuccess: () => {
          refetch();
          toast.custom((t) => {
            return (
              <Toast
                variant="success"
                Title={updateModuleSuccessTitle}
                SubTitle={updateModuleSuccessMessage}
                toastInstance={t}
              />
            );
          });
          setIsModuleModalOpen(false);
        },
        onError: (error) => {
          console.error('Error while updating module %o', error);
          toast.custom((t) => (
            <Toast
              variant="error"
              Title={updateModuleFailureTitle}
              SubTitle={updateModuleFailureMessage}
              toastInstance={t}
            />
          ));
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (modalType === 'create') {
          handleSubmit(createModuleHandler)(e);
        } else if (modalType === 'update') {
          handleSubmit(updateModuleHandler)(e);
        } else if (modalType === 'manage') {
          if (errorType === COURSES_ERROR_CODE.MODULE_ID_NOT_FOUND) {
            handleSubmit(createModuleInMultiLangHandler)(e);
          } else {
            handleSubmit(updateModuleHandler)(e);
          }
        }
      }}
      className="flex flex-col gap-4 overflow-y-scroll"
    >
      {/* Module Name */}
      <div className="w-full">
        <Label htmlFor="module_name" label={nameLabel} className="mb-1" />
        <TextInput
          type="text"
          id="module_name"
          className="mt-0.5 h-9 w-full rounded-md"
          placeholder={namePlaceholder}
          aria-invalid={fieldHasError(errors, 'name') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'name')}
          {...register('name')}
        />
        <HookFormErrorMessage
          name="name"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Module Name */}

      {/* Module Description */}
      <div className="w-full">
        <Label htmlFor="module_description" label={descriptionLabel} className="mb-1" />
        <TextAreaInput
          id="module_description"
          style={{ resize: 'none' }}
          className="mt-0.5 h-28 rounded-md"
          placeholder={descriptionPlaceholder}
          aria-invalid={fieldHasError(errors, 'description') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'description')}
          {...register('description')}
        />
        <HookFormErrorMessage
          name="description"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Module Description */}
      {/* Module Type */}
      <div className={cn('w-full', isFieldDisabled && 'opacity-50')}>
        <Label htmlFor="module_type" label={moduleTypeLabel} className="mb-1" />
        <Controller
          name="moduleType"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <Select
              id="module_type"
              className="mt-0.5 rounded-md"
              options={moduleType}
              placeholder={moduleTypePlaceholder}
              styles={{
                ...customStylesOne,
                control: (provided) => ({
                  ...provided,
                  background: '#18181B',
                  border: '1px solid #3F3F46 !important',
                  borderRadius: '6px !important',
                  boxShadow: 'none !important',
                }),
              }}
              classNames={{
                control: () => 'mt-0.5',
              }}
              value={moduleType.find((module) => module.value === value)}
              onChange={(data: any) => {
                onChange(data.value);
              }}
              isDisabled={isProgress || initiating || isFieldDisabled}
            />
          )}
        />
        <HookFormErrorMessage
          name="moduleType"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Module Type */}
      {/* Module Duration */}
      {moduleDurationVisible && (
        <div className={cn('w-full', isFieldDisabled && 'opacity-50')}>
          <Label htmlFor="module_duration" label={durationLabel} className="mb-1" />
          <TextInput
            type="number"
            min="1"
            id="module_duration"
            className="mt-0.5 h-9 w-full rounded-md"
            placeholder={durationPlaceholder}
            aria-invalid={fieldHasError(errors, 'moduleDuration') ? 'true' : 'false'}
            hasError={fieldHasError(errors, 'moduleDuration')}
            disabled={isFieldDisabled}
            {...register('moduleDuration')}
          />
          <HookFormErrorMessage
            name="moduleDuration"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
      )}
      {/* Module Duration */}
      {/* Module Speakers */}
      <div className={cn('w-full', isFieldDisabled && 'opacity-50')}>
        <Label htmlFor="module_speakers" label={speakerLabel} className="mb-1" />
        <Controller
          name="speaker"
          control={control}
          defaultValue={[]}
          render={({ field: { value } }) => (
            <Select
              id="module_speakers"
              className="mt-0.5"
              isMulti
              closeMenuOnSelect={false}
              options={speakersFetch.data?.speakers}
              isLoading={speakersFetch.isLoading}
              styles={{
                ...customStylesOne,
                control: (provided) => ({
                  ...provided,
                  background: '#18181B',
                  border: '1px solid #3F3F46 !important',
                  borderRadius: '6px !important',
                  boxShadow: 'none !important',
                }),
              }}
              placeholder={speakerPlaceholder}
              // defaultValue={
              value={value?.map(
                (val) =>
                  speakersFetch?.data?.speakers.find(
                    (speaker: { value: any }) => speaker.value === val.value
                  ) || val
              )}
              onChange={(speaker: any) => {
                setValue('speaker', speaker);
                trigger('speaker');
              }}
              classNames={{
                control: () => 'overflow-x-auto',
              }}
              isDisabled={isFieldDisabled}
            />
          )}
        />
        <HookFormErrorMessage
          name="speaker"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Module Speakers */}

      {/* Module Thumbnail  */}
      <div className="w-full">
        <Label htmlFor="module_thumbnail" label={thumbnailLabel} className="mb-1" />
        <Controller
          name="thumbnailUrl"
          control={control}
          defaultValue=""
          render={({ field: { value } }) => (
            <MediaUploadDropZone
              fileType={thumbnailType}
              maxSize={thumbnailMaxValue}
              onDropCallback={onDropThumbnail}
              isProgress={thumbnailProgress}
              isUploaded={value && value.length > 0 ? true : isThumbnailUploaded}
              progress={thumbnailProgressPercentage}
              cancel={onThumbnailUploadCancel}
              initiatingUpload={thumbnailInitiating}
              loader="progress"
              previewImage={
                value
                  ? generateCDNMediaAssetURLWithKey({
                      key: value,
                    })
                  : undefined
              }
              resetMedia={() => {
                setValue('thumbnailUrl', '');
                trigger('thumbnailUrl');
                setIsThumbnailUploaded(false);
              }}
              isPreviewImage={true}
            />
          )}
        />
        <HookFormErrorMessage
          name="thumbnailUrl"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Module Thumbnail  */}

      {/* Module Upload Media  */}
      {getValues('moduleType') && (
        <div className="w-full">
          <Label htmlFor="module_upload_media" label={mediaLabel} className="mb-1" />
          <Controller
            name="mediaUrl"
            control={control}
            defaultValue=""
            render={({ field: { value } }) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <MediaUploadDropZone
                  fileType={mediaMessage && mediaMessage.label}
                  maxSize={mediaMessage && mediaMessage.size}
                  onDropCallback={onDropMedia}
                  isProgress={isProgress}
                  isUploaded={value && value.length > 0 ? true : isMediaUploaded}
                  progress={progress}
                  cancel={onCancel}
                  aborted={aborted}
                  initiatingUpload={initiating}
                  loader="progress"
                  previewImage={
                    getValues('thumbnailUrl')
                      ? generateCDNMediaAssetURLWithKey({
                          key: getValues('thumbnailUrl'),
                        })
                      : undefined
                  }
                  resetMedia={() => {
                    setValue('mediaUrl', '');
                    trigger('mediaUrl');
                    setCurrentMedia(null); // remove the media on reset
                    setIsMediaUploaded(false);
                  }}
                  isPreviewMedia={true}
                  previewMedia={currentMedia}
                  mediaType={
                    getValues('moduleType') as (typeof MODULE_TYPE)[keyof typeof MODULE_TYPE]
                  }
                />
              </div>
            )}
          />
          <HookFormErrorMessage
            name="mediaUrl"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
      )}
      {/* Module Upload Media  */}

      {/* Module Upload closedCaptionsUrls  */}
      {(getValues('moduleType') == MODULE_TYPE.VIDEO ||
        getValues('moduleType') == MODULE_TYPE.AUDIO) && (
        <div className="w-full">
          <Label
            htmlFor="module_upload_closedCaption"
            label={closedCaptionsLabel}
            className="mb-1"
          />
          <Controller
            name="closedCaptionsUrls"
            control={control}
            defaultValue={[]}
            render={() => (
              <MediaUploadDropZone
                loader="progress"
                onDropCallback={onDropclosedCaption}
                fileType={closedCaptionsType}
                maxSize={closedCaptionsMaxValue}
                isUploaded={false}
                isProgress={isClosedCaptionProgress}
                progress={closedCaptionProgress}
                cancel={onCancelClosedcaption}
                initiatingUpload={closedCaptionInitiating}
                aborted={closedCaptionAborted}
              />
            )}
          />
        </div>
      )}
      {(getValues('moduleType') == MODULE_TYPE.VIDEO ||
        getValues('moduleType') == MODULE_TYPE.AUDIO) &&
        closedCaptions && (
          <div className="w-full">
            <ClosedCaptionsTable
              data={closedCaptions}
              className="border border-zinc-700"
              setClosedCaptions={setClosedCaptions}
            ></ClosedCaptionsTable>

            <HookFormErrorMessage
              name="closedCaptionsUrls"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </div>
        )}
      {/* Module Upload closedCaptionsUrls  */}

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {cancelButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            // When the mutation is in progress
            createModuleMutation.isLoading ||
            updateModuleMutation.isLoading ||
            createModuleInMultiLangMutation.isLoading ||
            // When the speakers are not loaded disable the button
            speakersFetch.isLoading ||
            isProgress ||
            thumbnailProgress ||
            isClosedCaptionProgress ||
            Object.keys(errors).length !== 0
          }
        >
          {createModuleMutation.isLoading ||
          updateModuleMutation.isLoading ||
          createModuleInMultiLangMutation.isLoading ||
          modulesAssessmentPrefetchData?.isLoading ? (
            <div className="flex gap-2">
              <span>{formActionButtonLabel}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>{formActionButtonLabel}</span>
              {buttonIcon}
            </div>
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default ModuleAssessmentForm;
