import { Play } from 'phosphor-react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import useTimer from '../../../../../../../../hooks/use-timer';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import {
  OVERLAY_TOTAL_STEPS,
  OVERLAY_STEP_INTERVAL,
  OVERLAY_STEP_DELAY,
} from '../../constants/overlayConstants';

type Props = {
  onOutsideClick: () => void;
  onNextModuleClick: (event: any) => void;
  onComplete: () => void;
};
const OverlayVideoComponent = (props: Props) => {
  const { onOutsideClick, onNextModuleClick, onComplete } = props;
  const { percentageComplete } = useTimer({
    totalSteps: OVERLAY_TOTAL_STEPS,
    stepInterval: OVERLAY_STEP_INTERVAL,
    delayMS: OVERLAY_STEP_DELAY,
    onComplete,
  });
  const { singleModuleCommon } = useStrapiCoursesData();
  return (
    <div
      className="absolute z-50 flex h-full w-full  flex-col items-center justify-center bg-black/50"
      onClick={onOutsideClick}
    >
      <div
        className="flex  cursor-pointer flex-col items-center text-sm text-white"
        onClick={onNextModuleClick}
      >
        <div className="h-[3.75rem] w-[3.75rem]">
          <CircularProgressbarWithChildren
            value={percentageComplete}
            styles={buildStyles({
              pathTransition: 'stroke-dashoffset 0.5s ease 0s',
            })}
          >
            <Play size={32} />
          </CircularProgressbarWithChildren>
        </div>
        <span className="my-2"> {singleModuleCommon.nextButton}</span>
      </div>
    </div>
  );
};

export default OverlayVideoComponent;
