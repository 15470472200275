export interface GetPresignedUrlFilters {
  module: (typeof MODULE_TYPES)[keyof typeof MODULE_TYPES];
}

export interface PresignedUrlResponse {
  url: string;
  key: string;
}

export interface DownloadPresignedUrlFilter {
  key: string;
}

export const FILE_TYPES = {
  THUMBNAIL: 'thumbnail',
  PREVIEW_VIDEO: 'preview_video',
} as const;

export const MODULE_TYPES = {
  COURSES: 'courses',
  MODULE: 'module',
  ASSESSMENT: 'assessment',
  SPEAKER: 'speaker',
  USER: 'user',
  ENT_BULK_USER_INVITE_CSV_UPLOAD: 'ent_bulk_user_invite',
} as const;

export interface MultipartPreSignedUrlRequestPayload {
  fileExtension: string;
  parts?: number;
  requestType: string;
  module: uploadModuleType;
  courseId?: string;
  fileType: string;
  fileName?: string;
}

export type uploadModuleType = 'course' | 'module' | 'assessment';
