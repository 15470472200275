import * as DropDownMenu from '@radix-ui/react-dropdown-menu';
import { ArrowsDownUp, DotsThreeVertical, Pencil, ShieldSlash } from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '../../../../../../../../components/Dialog';
import { USER_STATUS } from '../../../../../../constants';
import {
  ActivateUserDialogContent,
  DeactivateUserDialogContent,
  SubscriptionLimitExceededModal,
  TrialLimitExceededDialogContent,
  DisableMFAForUserDialogContent,
} from './UserActionsDialogContent';
import UserActivateOrDeactivateItem from './UserActivateOrDeactivateItem';
import ResendInvite from '../../../../ResendInvite/ResendInvite';
import useResendInviteMutation from '../../../../../../../teams/hooks/useResendInviteMutation';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../../../../../../components/Toast';
import useStrapiMembersData from '../../../../../../hooks/useStrapiMembersData';
import { useReduxAuthState } from '../../../../../../../../hooks';
import UserRoleUpdate from '../../../../UserRoleUpdate/UserRoleUpdate';
import { ENTERPRISE_USER_ROLES, MFAMethod } from '../../../../../../../../constants';
import UserChangeTeam from '../../../../UserChangeTeam/UserChangeTeam';

interface EnterpriseDropDownCellProps {
  username: string;
  status: keyof typeof USER_STATUS;
  teamId?: number;
  teamName: string;
  activateLabel: string;
  deactivateLabel: string;
  resendInviteLabel: string;
  disableMFALabel: string;
  userRole: string;
  fullName: string;
  changeTeamLabel: string;
  changeRoleLabel: string;
  mfaPreference?: string;
}

export type DialogType =
  | 'ACTIVATE_USER'
  | 'DEACTIVATE_USER'
  | 'TRIAL_LIMIT_EXCEEDED'
  | 'SUBSCRIPTION_LICENSES_EXCEEDS'
  | 'DISABLE_MFA_FOR_USER_CONFIRM'
  | 'UPDATE_USER_ROLE'
  | 'CHANGE_TEAM';

/**
 * User actions to be displayed on the all members table for enterprise admin screen.
 */
const EnterpriseDropDownCell = (props: EnterpriseDropDownCellProps) => {
  const {
    username,
    status,
    teamId,
    resendInviteLabel,
    deactivateLabel,
    activateLabel,
    disableMFALabel,
    userRole,
    fullName,
    changeRoleLabel,
    teamName,
    changeTeamLabel,
    mfaPreference,
  } = props;

  const { user } = useReduxAuthState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeDialogType, setActiveDialogType] = useState<DialogType | null>(null);
  const [updateRoleModalOpen, setUpdateRoleModalOpen] = useState<boolean>(false);

  // Close Dialog
  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);
  // Determine the DialogContent to show based on the activeDialogType
  const DialogContent = useMemo(() => {
    switch (activeDialogType) {
      case 'ACTIVATE_USER': {
        return (
          <ActivateUserDialogContent
            teamId={teamId}
            username={username}
            closeDialog={closeDialog}
            setActiveDialogType={setActiveDialogType}
          />
        );
      }
      case 'DEACTIVATE_USER': {
        return (
          <DeactivateUserDialogContent
            username={username}
            closeDialog={closeDialog}
            teamId={teamId}
          />
        );
      }
      case 'TRIAL_LIMIT_EXCEEDED': {
        return <TrialLimitExceededDialogContent />;
      }
      case 'SUBSCRIPTION_LICENSES_EXCEEDS': {
        return <SubscriptionLimitExceededModal />;
      }
      case 'DISABLE_MFA_FOR_USER_CONFIRM': {
        return <DisableMFAForUserDialogContent username={username} closeDialog={closeDialog} />;
      }
      case 'UPDATE_USER_ROLE': {
        return (
          <UserRoleUpdate
            currentRole={userRole}
            username={username}
            fullName={fullName}
            setIsModalOpen={setUpdateRoleModalOpen}
          />
        );
      }
      case 'CHANGE_TEAM': {
        return (
          <UserChangeTeam
            currentRole={userRole}
            username={username}
            fullName={fullName}
            setIsModalOpen={setUpdateRoleModalOpen}
            teamName={teamName}
          />
        );
      }
    }
  }, [activeDialogType, closeDialog, username, teamId]);

  const { mutate, isLoading } = useResendInviteMutation();
  const {
    toastMessage: { resendInviteSuccess, resendInviteFailure },
  } = useStrapiMembersData();
  const onResendInvite = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (username) {
      mutate(username, {
        onSuccess: () => {
          setIsDialogOpen(false);
          toast.custom((t) => {
            return <Toast variant="success" Title={resendInviteSuccess} toastInstance={t} />;
          });
        },
        onError: (error) => {
          console.error('Error while inviting user %o', error);
          toast.custom((t) => (
            <Toast variant="error" Title={resendInviteFailure} toastInstance={t} />
          ));
        },
      });
    }
  };

  // If the user row is the current user record, then return null
  // This will satisfy for only admins right now
  // since the options are only shown to enterprise admins
  if (username === user?.email) {
    return null;
  }

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DropDownMenu.Root>
        <DropDownMenu.Trigger>
          <DotsThreeVertical className="text-zinc-100" color="currentColor" size={16} />
        </DropDownMenu.Trigger>
        <DropDownMenu.Portal>
          <DropDownMenu.Content
            align="end"
            className="z-20 flex flex-col rounded-lg border border-zinc-700 bg-zinc-800 shadow-[0px_25px_50px_-12px_rgba(0,0,0,0.25)]"
          >
            {/* Update team Modal*/}
            {(userRole === ENTERPRISE_USER_ROLES.MANAGER ||
              userRole === ENTERPRISE_USER_ROLES.LEARNER) && (
              <div className="flex items-center justify-center p-1">
                <DropDownMenu.Item
                  onClick={() => {
                    setActiveDialogType('CHANGE_TEAM');
                    setUpdateRoleModalOpen(true);
                  }}
                  className="flex h-8 w-full cursor-pointer items-center gap-2.5 rounded-md pl-2 pr-4 text-zinc-50 hover:focus:bg-zinc-700"
                >
                  <Pencil size={14} color="currentColor" className="text-inherit" />
                  <span className="text-xs font-medium leading-5 text-inherit">
                    {changeTeamLabel}
                  </span>
                </DropDownMenu.Item>
              </div>
            )}
            {/* Update Role Modal*/}
            {userRole !== ENTERPRISE_USER_ROLES.ADMIN && (
              <div className="flex items-center justify-center p-1">
                <DropDownMenu.Item
                  onClick={() => {
                    setUpdateRoleModalOpen(true);
                    setActiveDialogType('UPDATE_USER_ROLE');
                  }}
                  className="flex h-8 w-full cursor-pointer items-center gap-2.5 rounded-md pl-2 pr-4 text-zinc-50 hover:focus:bg-zinc-700"
                >
                  <ArrowsDownUp size={14} color="currentColor" className="text-inherit" />
                  <span className="text-xs font-medium leading-5 text-inherit">
                    {changeRoleLabel}
                  </span>
                </DropDownMenu.Item>
              </div>
            )}

            <hr className="h-px border-0 bg-zinc-700" />

            <div className="flex items-center justify-center p-1">
              <UserActivateOrDeactivateItem
                onClick={() => {
                  // TODO: Check with @Nirmal
                  if (status === USER_STATUS.ACTIVE || status === USER_STATUS.INVITED) {
                    setActiveDialogType('DEACTIVATE_USER');
                  } else if (status === USER_STATUS.DISABLED) {
                    setActiveDialogType('ACTIVATE_USER');
                  }
                  setIsDialogOpen(true);
                }}
                status={status}
                className="h-8 w-full cursor-pointer rounded-md pl-2 pr-4 hover:focus:bg-zinc-700"
                activateLabel={activateLabel}
                deactivateLabel={deactivateLabel}
              />
            </div>
            {status === USER_STATUS.INVITED && (
              <div className="flex items-center justify-center p-1">
                <ResendInvite
                  onResendInvite={onResendInvite}
                  isLoading={isLoading}
                  className="h-8 cursor-pointer rounded-md pl-2 pr-4 hover:focus:bg-zinc-700"
                  title={resendInviteLabel}
                />
              </div>
            )}
            {/* Disable MFA for user item */}
            {!(mfaPreference === MFAMethod.NOMFA) && (
              <div className="flex items-center justify-center p-1">
                <DropDownMenu.Item
                  onClick={() => {
                    setActiveDialogType('DISABLE_MFA_FOR_USER_CONFIRM');
                    setIsDialogOpen(true);
                  }}
                  className="flex h-8 w-full cursor-pointer items-center gap-2.5 rounded-md pl-2 pr-4 text-zinc-50 hover:focus:bg-zinc-700"
                >
                  <ShieldSlash className="text-inherit" color="currentColor" size={14} />
                  <span className="text-xs font-medium leading-5 text-inherit">
                    {disableMFALabel}
                  </span>
                </DropDownMenu.Item>
              </div>
            )}
          </DropDownMenu.Content>
        </DropDownMenu.Portal>
      </DropDownMenu.Root>

      {/* Dialog */}
      <Dialog
        Content={DialogContent}
        open={isDialogOpen}
        onOpenChange={(open) => {
          setIsDialogOpen(open);
        }}
        // Donot close the modal when clicking outside the alert dialog
        onInteractOutside={(event) => {
          event.preventDefault();
        }}
      ></Dialog>

      {/* User Role Update */}
      <Dialog
        containerClassName="!max-w-1xl"
        triggerClassName="h-max !w-fit flex flex-row gap-1 xxs:gap-2 items-center rounded bg-zinc-800 py-3 px-4 md:px-4"
        open={updateRoleModalOpen}
        onOpenChange={(open) => setUpdateRoleModalOpen(open)}
        Content={DialogContent}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default EnterpriseDropDownCell;
