import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB } from '../../constants';
import { FILE_UPLOAD_ERROR } from './EnterpriseBulkInviteUser';

interface Props {
  error: FILE_UPLOAD_ERROR;
}

const FileUploadError = (props: Props) => {
  const { error } = props;

  switch (error) {
    case 'EXCEEDS_SIZE_LIMIT': {
      return (
        <ErrorMessage
          className="text-sm"
          message={`File size should be smaller than ${ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB} MB.`}
        />
      );
    }
  }
};

export default FileUploadError;
