import { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../../components/Toast';
import { MIME_TYPE } from '../../../../constants';
import { MODULE_TYPES } from '../../../../types';
import { validateFileSize } from '../../../../utils';
import useStrapiMembersData from '../../../members/hooks/useStrapiMembersData';
import { ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB } from '../../constants';
import { useFileUploadMutation } from '../../hooks';
import useGetUploadPresignedUrlMutation from '../../hooks/useGetUploadPresignedUrlMutation';
import FileUploadComplete from './FileUploadComplete';
import FileUploadDropzone from './FileUploadDropzone';
import FileUploadError from './FileUploadError';
import FileUploadInProgress from './FileUploadInProgress';

interface EnterpriseBulkInviteUserProps {
  setUploadId: (uploadId: string | null) => void;
}

export type FILE_UPLOAD_ERROR = 'EXCEEDS_SIZE_LIMIT';

const EnterpriseBulkInviteUser = (props: EnterpriseBulkInviteUserProps) => {
  const { setUploadId } = props;
  const {
    toastMessage: { InitiatingBulkUploadFailure },
  } = useStrapiMembersData();
  const [bulkUploadCSVFile, setBulkUploadCSVFile] = useState<File | null>(null);
  const [error, setError] = useState<FILE_UPLOAD_ERROR | null>(null);

  const [uploadAbortController, setUploadAbortController] = useState<null | AbortController>(null);

  const fileUploadMutation = useFileUploadMutation();
  const getUploadPresignedUrlMutation = useGetUploadPresignedUrlMutation();

  // File drop handler
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setError(null);

        const csvFile = acceptedFiles[0];
        setBulkUploadCSVFile(csvFile);

        const isLowerThanSizeLimit = validateFileSize({
          maxSize: {
            MB: ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB,
          },
          sizeInBytes: csvFile.size,
        });

        if (!isLowerThanSizeLimit) {
          setError('EXCEEDS_SIZE_LIMIT');
          return;
        }

        // Get the presigned url
        const { url, key: s3Key } = await getUploadPresignedUrlMutation.mutateAsync(
          {
            module: MODULE_TYPES.ENT_BULK_USER_INVITE_CSV_UPLOAD,
          },
          {
            onError: () => {
              toast.custom((t) => (
                <Toast toastInstance={t} Title={InitiatingBulkUploadFailure} variant="error" />
              ));
            },
          }
        );

        // Extract the file name
        const preSignedURLParts = s3Key.split('/');
        const fileNameWithoutExt = preSignedURLParts[preSignedURLParts.length - 1].split('.')[0];

        // If there is abort controller use it else set it to null
        const abortController = window.AbortController ? new window.AbortController() : null;
        setUploadAbortController(abortController);

        // Upload the file to s3
        await fileUploadMutation.mutateAsync({
          file: csvFile,
          uploadUrl: url,
          signal: abortController?.signal,
        });

        setUploadId(fileNameWithoutExt);
      } catch (error) {
        console.error('Error uploading file to the bulk upload csv', error);
      }
    },
    [setUploadId]
  );

  // Reset File Upload
  const resetUpload = () => {
    fileUploadMutation.reset();
    setBulkUploadCSVFile(null);
    setUploadId(null);
    setUploadAbortController(null);
  };

  // Cancel File Upload
  const cancelUpload = () => {
    uploadAbortController && uploadAbortController.abort();
    resetUpload();
  };

  // When we are either fetching the presigned url or uploading the file
  const isInProgress = fileUploadMutation.isLoading || getUploadPresignedUrlMutation.isLoading;

  // When we have already uploaded a file or the file upload is in progress
  // Donot show the loader
  const showDropzone = !isInProgress && !fileUploadMutation.isSuccess;

  return (
    <>
      <div className="flex min-h-[150px] flex-col items-center rounded-md border-[2px] border-dashed border-zinc-600">
        {/* Bulk upload hasn't started yet */}
        {showDropzone && (
          <FileUploadDropzone
            dropzoneOptions={{
              onDrop,
              accept: {
                [MIME_TYPE.csv]: [],
              },
            }}
          />
        )}
        {/* Bulk Upload In Progress */}
        {isInProgress && (
          <FileUploadInProgress
            isInitiating={getUploadPresignedUrlMutation.isLoading}
            isUploading={fileUploadMutation.isLoading}
            showProgress={true}
            uploadProgress={fileUploadMutation.uploadProgress}
            file={bulkUploadCSVFile as File}
            cancelUpload={cancelUpload}
          />
        )}
        {/* Bulk Upload Complete */}
        {fileUploadMutation.isSuccess && (
          <FileUploadComplete file={bulkUploadCSVFile as File} resetUpload={resetUpload} />
        )}
      </div>
      {error && (
        <div className="flex w-full justify-center">
          <FileUploadError error={error} />
        </div>
      )}
    </>
  );
};

export default EnterpriseBulkInviteUser;
