import { useMutation } from '@tanstack/react-query';
import { getPresignedUrl } from '../../../api';
import { MODULE_TYPES } from '../../../types';

type MODULE_TYPES_KEYS = keyof typeof MODULE_TYPES;

const useGetUploadPresignedUrlMutation = () => {
  const mutation = useMutation({
    mutationFn: async (args: { module: typeof MODULE_TYPES[MODULE_TYPES_KEYS] }) => {
      const { module } = args;

      const response = await getPresignedUrl({
        presignedUrlFilters: {
          module,
        },
      });
      return response;
    },
  });

  return mutation;
};

export default useGetUploadPresignedUrlMutation;
