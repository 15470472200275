import * as yup from 'yup';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { Label } from '../../../../../components/FormElements';
import Select from 'react-select';
import React, { useState } from 'react';
import { ENTERPRISE_USER_ROLES } from '../../../../../constants';
import CreatableSelect from 'react-select/creatable';
import useGetTeamsQuery from '../../../hooks/useGetTeamsQuery';
import Dialog from '../../../../../components/Dialog';
import { Button } from '../../../../../components/Buttons';
import useUpdateRoleOrTeamMutation from '../../../hooks/useUpdateRoleOrTeamMutation';
import { BasicSpinner } from '../../../../../components/Spinners';
import Alert from '../../../../../components/Alert';
import axios from 'axios';
import { UserUpdateRolePayloadType } from '../../../api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import IconWithModal from '../../../../../components/IconWithModal';
import RoleDescription from '../../../../shared/components/RoleDescription';
import { ArrowClockwise, Question } from 'phosphor-react';
import { queryClient } from '../../../../../config/react-query';
import { showToast } from '../../../../shared/helpers/toast';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { customStylesOne } from '../../../../../styles/modalCustomStyle';

type props = {
  currentRole: string;
  setIsModalOpen: any;
  username: string;
  strapiData: any;
};

type schemaProps = {
  userRoleRequiredError: string;
  invalidUserRoleError: string;
  roleFieldLabel: string;
  teamDetailRequiredError: string;
  teamFieldLabel: string;
};

const getSchema = ({
  userRoleRequiredError,
  invalidUserRoleError,
  roleFieldLabel,
  teamDetailRequiredError,
  teamFieldLabel,
}: schemaProps) => {
  return yup.object({
    role: yup
      .string()
      .required(userRoleRequiredError)
      .equals(
        [ENTERPRISE_USER_ROLES.ADMIN, ENTERPRISE_USER_ROLES.LEADER, ENTERPRISE_USER_ROLES.MANAGER],
        invalidUserRoleError
      )
      .label(roleFieldLabel),
    team: yup
      .object()
      .when(roleFieldLabel, {
        is: ENTERPRISE_USER_ROLES.MANAGER,
        then: yup.object().required(teamDetailRequiredError),
      })
      .label(teamFieldLabel),
  });
};

const UserRoleUpdateModal = ({ currentRole, setIsModalOpen, username, strapiData }: props) => {
  const {
    roleInputPlaceHolder,
    teamInputLabel,
    roleInputLabel,
    teamFieldLabel,
    teamInputPlaceHolder,
    teamDetailRequiredError,
    roleFieldLabel,
    invalidUserRoleError,
    userRoleRequiredError,
    cancelButtonText,
    updateRoleButtonText,
    SuccessMessageHead,
    SuccessMessageBody,
    teamsFetchErrorMessage,
  } = strapiData;
  const strapiError: any = useStrapiDataHelper();
  const strapiErrorMessage = strapiError.errorsMap;

  const userRoleUpdateSchema = getSchema({
    userRoleRequiredError,
    invalidUserRoleError,
    roleFieldLabel,
    teamDetailRequiredError,
    teamFieldLabel,
  });
  const { isLoading, isError, error, mutate } = useUpdateRoleOrTeamMutation();
  const [alertError, setAlertError] = useState<string>();
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(userRoleUpdateSchema) });
  const onSuccess = (role: string) => {
    showToast({
      variant: 'success',
      title: SuccessMessageHead,
      body: `${SuccessMessageBody} ${role}`,
    });
    setIsModalOpen(false);
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'organization',
          item: 'get-members',
        },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'teams',
          items: 'TeamMembers',
        },
      ],
    });
  };

  const onSubmit: any = (data: yup.InferType<typeof userRoleUpdateSchema>) => {
    switch (data.role) {
      case ENTERPRISE_USER_ROLES.ADMIN:
      case ENTERPRISE_USER_ROLES.LEADER: {
        mutate(
          { username, role: data.role },
          {
            onSuccess: () => onSuccess(data.role),
            onError: (error) => {
              if (axios.isAxiosError(error)) {
                const data: any = error.response?.data;
                switch (data?.errorCode) {
                  case 'TEAM_REQUIRES_ATLEAST_ONE_MANAGER':
                    setAlertError(strapiErrorMessage['ONLY_MANAGER_CHANGE_RESTRICTED']);
                    break;
                  default:
                    setAlertError(data?.errorMessage);
                  // #markedforlookup
                }
              }
            },
          }
        );
        break;
      }
      case ENTERPRISE_USER_ROLES.MANAGER: {
        let payload = {} as UserUpdateRolePayloadType;
        payload = {
          username,
          role: data.role,
          [data.team.__isNew__ ? 'teamName' : 'teamId']: data.team.value,
        };
        mutate(payload, { onSuccess: () => onSuccess(data.role) });
      }
    }
  };

  let roleDropDown: { label: string; value: string }[] = [];
  switch (currentRole) {
    case ENTERPRISE_USER_ROLES.LEADER: {
      roleDropDown = [{ label: ENTERPRISE_USER_ROLES.ADMIN, value: ENTERPRISE_USER_ROLES.ADMIN }];
      break;
    }
    case ENTERPRISE_USER_ROLES.MANAGER: {
      roleDropDown = [{ label: ENTERPRISE_USER_ROLES.LEADER, value: ENTERPRISE_USER_ROLES.LEADER }];
      break;
    }
    case ENTERPRISE_USER_ROLES.LEARNER: {
      roleDropDown = [
        {
          label: ENTERPRISE_USER_ROLES.MANAGER,
          value: ENTERPRISE_USER_ROLES.MANAGER,
        },
      ];
    }
  }

  const rolesInDropDownOptions: string[] = roleDropDown.map((role) => role.value);

  const {
    data,
    isLoading: isLoadingTeams,
    refetch,
    isError: isErrorTeams,
    isRefetching: isReFetchingTeams,
  } = useGetTeamsQuery({
    includeSubTeams: true,
    enabled: getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.MANAGER,
  });

  return (
    <>
      {isError && error && <Alert variant="error" message={alertError} />}
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* Select a role */}
        <div>
          <div className="flex flex-row items-center gap-1">
            <Label htmlFor={roleFieldLabel} label={roleInputLabel} />
            <IconWithModal
              icon={<Question size="14px" className="text-zinc-400" />}
              content={<RoleDescription roles={rolesInDropDownOptions} />}
              triggerClassName="max-w-max px-0.5"
              containerClassName="max-h-[80vh] overflow-y-auto z-[100]"
              overlayClassName={'z-[80] bg-black/40'}
            />
          </div>
          <Select
            id={roleInputLabel}
            options={roleDropDown}
            placeholder={roleInputPlaceHolder}
            styles={customStylesOne}
            classNames={{
              control: () => 'mt-0.5',
            }}
            aria-invalid={fieldHasError(errors, roleFieldLabel) ? 'true' : 'false'}
            {...register(roleFieldLabel)}
            onChange={(role: any) => {
              setValue('role', role.value);
              trigger(roleFieldLabel);
            }}
          />
          <HookFormErrorMessage
            name="role"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Select a team */}
        {getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.MANAGER && (
          <div className="mt-2">
            <div className="flex flex-col justify-between xs:flex-row">
              <Label htmlFor={`change-team_${teamFieldLabel}`} label={teamInputLabel} />
              {isErrorTeams && (
                <div className="flex flex-row items-center gap-1">
                  <p className="text-xs font-normal text-red-500">{teamsFetchErrorMessage}</p>
                  <ArrowClockwise
                    onClick={() => refetch()}
                    size={14}
                    weight="light"
                    color="#a1a1aa"
                    className="cursor-pointer hover:opacity-50"
                  />
                </div>
              )}
            </div>
            <CreatableSelect
              id="team"
              options={data}
              isLoading={isLoadingTeams || isReFetchingTeams}
              placeholder={teamInputPlaceHolder}
              styles={customStylesOne}
              classNames={{
                control: () => 'mt-0.5',
              }}
              aria-invalid={fieldHasError(errors, teamFieldLabel) ? 'true' : 'false'}
              {...register(teamFieldLabel)}
              onChange={(team) => {
                setValue('team', team);
                trigger(teamFieldLabel);
              }}
            />
            <HookFormErrorMessage
              name="team"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </div>
        )}
        <div className="mt-5 flex flex-col items-center justify-end xs:flex-row">
          <Dialog.Close
            type="button"
            className="rounded bg-transparent px-[30px] py-2.5 text-center text-sm font-medium text-white"
            onClick={() => setIsModalOpen(false)}
          >
            {cancelButtonText}
          </Dialog.Close>
          <Button
            className="flex items-center gap-1 rounded bg-base-brand px-[30px] py-2.5 text-center text-sm font-medium text-white disabled:opacity-80"
            type="submit"
            disabled={isLoading || isError || isErrorTeams}
          >
            <span>{updateRoleButtonText}</span>
            {isLoading && <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserRoleUpdateModal;
