import { ERROR_CODE } from '../../members/constants/errors';

interface GetInitBulkInviteErrorMessageArgs {
  errorCodeToMessageMap: Record<string, string>;
  errorCode: string;
  errorMessage: string;
  isOnboarding: boolean;
}

const getInitBulkInviteErrorMessage = (args: GetInitBulkInviteErrorMessageArgs) => {
  const { errorCode, errorMessage, errorCodeToMessageMap, isOnboarding } = args;

  let message: string | null = null;

  switch (errorCode) {
    case ERROR_CODE.BULK_UPLOAD_VALIDATION_FAILED: {
      message = errorCodeToMessageMap[errorMessage];
      break;
    }
    case ERROR_CODE.SUBSCRIPTION_LICENSES_EXCEEDS:
    case ERROR_CODE.TRIAL_LIMIT_EXCEEDED: {
      if (isOnboarding) {
        message = errorCodeToMessageMap[`${errorCode}_ONBOARDING`];
      } else {
        message = errorCodeToMessageMap[errorCode];
      }
      break;
    }
    case ERROR_CODE.USER_ALREADY_EXIST: {
      message = errorMessage;
      break;
    }
  }

  message = message ?? errorCodeToMessageMap[ERROR_CODE.BULK_UPLOAD_UNKNOWN_ERROR];

  return message;
};

export { getInitBulkInviteErrorMessage };
