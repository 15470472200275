import { useQuery } from '@tanstack/react-query';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
import { getAssessmentQuestionsById } from '../api';
import { useState } from 'react';
import axios from 'axios';
import { USHGAPIError } from '../../../types';
import { AssessmentQuestionsByIdResponse } from '../types';

interface Props {
  questionId: number;
  enabled?: boolean;
  language?: string;
}

const transformQuestionDetails = (question: AssessmentQuestionsByIdResponse) => {
  return {
    questionId: question.questionId,
    sortOrder: question.sortOrder,
    question: question.question,
    answer: question.answer?.map((value) => {
      return {
        value: String(value.answerValue),
      };
    }),
    correctOption: question.answer?.findIndex((value) => value.answerScore === '1') + 1,
    options: question.answer?.map((value: Record<string, any>) => {
      return {
        id: value.answerId,
        value: String(value.answerValue),
        score: value.answerId,
      };
    }),
  };
};

const useGetAssessmentQuestionsByIdQuery = ({
  questionId,
  enabled = true,
  language = DEFAULT_PREFERRED_LANGUAGE,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery({
    queryKey: [
      {
        scope: 'assessment-question',
        item: 'get-assessment-question-by-id',
        questionId: questionId,
        language: language,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { questionId, language } = queryKey[0];

      const question = await getAssessmentQuestionsById({ questionId, language });

      return transformQuestionDetails(question);
    },
    enabled: enabled,
    onError: (error) => {
      // If axios error
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data) {
          const { errorCode } = data as USHGAPIError;
          setErrorType(errorCode);
        }
      }
    },
  });

  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType, setErrorType };
};

export default useGetAssessmentQuestionsByIdQuery;
