import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiSharedData = () => {
  const data: any = useAppSelector(strapiDataSelector);
  const strapiShared = data.shared.data.attributes;
  const mediaUpload = strapiShared.MediaUploadModal;
  const tableProps = strapiShared.TableProps;
  const inviteFriendModal = strapiShared.inviteFriendsModal;
  const userCard = strapiShared.UserCard;
  const subscriptionInfo = strapiShared.SubscriptionInfo;
  const subscriptionReminder = strapiShared.SubscriptionReminder;
  const pricingPlanAvatar = strapiShared.PricingPlanAvatar;
  const userType = strapiShared.UserType;
  const componentLevelErrorBoundary = strapiShared.ComponentLevelErrorBoundary;
  const applicationLevelErrorBoundary = strapiShared.ApplicationLevelErrorBoundary;
  const bulkUpload = strapiShared.BulkInviteModal;
  const pageNotFound = strapiShared.PageNotFound;
  const contactUsEmail = strapiShared.contactUsEmail;
  const SSO = strapiShared.SSO;
  const featureFlags = strapiShared.featureFlags;
  const newFeatureLabel = strapiShared.newFeatureLabel;
  const Exception = strapiShared.Exception;
  const PasswordRequirements = strapiShared.PasswordRequirements;
  const RichTextEditor = strapiShared.RichTextEditor;
  const roleDescription = strapiShared.RoleDescription;
  const dialog = strapiShared.Dialog;
  const helpAndResourcesWidget = strapiShared.HelpAndResourcesWidget;
  const userDefaultProfilePicture = strapiShared.userDefaultProfilePicture;
  const skipToMainContentLinkLabel = strapiShared.skipToMainContentLinkLabel;
  const srPasswordToggle = strapiShared.SRPasswordToggle;
  const language = strapiShared.Language;
  const durationLabels = strapiShared.DurationLabels;
  const spinner = strapiShared.Spinner;
  return {
    contactUsEmail,
    pageNotFound,
    bulkUpload,
    userType,
    mediaUpload,
    inviteFriendModal,
    userCard,
    subscriptionInfo,
    tableProps,
    subscriptionReminder,
    pricingPlanAvatar,
    componentLevelErrorBoundary,
    applicationLevelErrorBoundary,
    SSO,
    featureFlags,
    newFeatureLabel,
    Exception,
    PasswordRequirements,
    RichTextEditor,
    roleDescription,
    dialog,
    helpAndResourcesWidget,
    userDefaultProfilePicture,
    skipToMainContentLinkLabel,
    srPasswordToggle,
    language,
    durationLabels,
    spinner,
  };
};
