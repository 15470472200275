import PageHeader from './components';
import PageTable from './components/PageTable';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';

const EnterpriseAdminAllMembersMain = () => {
  return (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <PageHeader className="w-full py-6 px-3 xs:px-4 sm:px-5 md:py-5 md:px-6 lg:py-6 lg:px-7 xl:py-7 xl:px-8 2xl:px-9" />
      <SideBarLayoutPageContentWrapper>
        <PageTable className="flex w-full flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6" />
      </SideBarLayoutPageContentWrapper>
    </main>
  );
};

export default EnterpriseAdminAllMembersMain;
