import { ArrowLeft, ArrowRight, Check, Plus } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../../../../constants';
import { COURSE_STATES } from '../../constants/constants';
import Dialog from '../../../../../../components/Dialog';
import React, { useState } from 'react';
import AssignCourseModal from '../AssignCourse/AssignCourseModal';
import { Button } from '../../../../../../components/Buttons';
import { BasicSpinner } from '../../../../../../components/Spinners';
import useSelfAssignCourseMutation from '../../../../hooks/useSelfAssignCourseMutation';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../../../../components/Toast';
import { useQueryClient } from '@tanstack/react-query';
import HasAccess from '../../../../../shared/components/HasAccess';
import { getCourseModulesQueryKey } from '../SingleModuleComponent/constants/queryKeyConstants';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import useFeedbackSubmitMutation from '../../../../hooks/useFeedbackSubmitMutation';
import { submitFeedbackFormType, submitFeedbackPayloadType } from '../../types/SingleCourseTypes';
import { FEEDBACK_TYPE } from '../SingleModuleComponent/constants/userRatingConstants';
import RatingModal from '../SingleModuleComponent/components/RatingModal/RatingModal';
import { useStrapiHQAdminCoursesData } from '../../../../../course-management/hooks/useStrapiHQAdminCourseData';
import cn from '../../../../../../utils/cn';

// Course Completed Badge

type CourseCompletedBadgeProps = {
  label: string;
  className?: string;
};

const CourseCompletedBadge = (props: CourseCompletedBadgeProps) => {
  const { label, className } = props;

  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-[52px] bg-[rgba(55,153,14,0.2)] py-2.5 px-3.5 text-xs text-white',
        className
      )}
    >
      <Check size={16} color="currentColor" weight="bold" className="shrink-0 text-inherit" />
      <span className="whitespace-nowrap">{label}</span>
    </div>
  );
};

// Course Completed Badge

interface SingleCourseHeaderProps {
  title: string;
  courseId: number;
  isAssigned: boolean;
  isCompleted: boolean;
  isStarted: boolean;
  refetchDetails?: () => void;
}

const SingleCourseHeader = ({
  title,
  courseId,
  isAssigned,
  isCompleted,
  isStarted,
  refetchDetails,
}: SingleCourseHeaderProps) => {
  const { id, journeyId } = useParams();
  const navigate = useNavigate();
  const [assignCourseModalOpen, setAssignCourseModalOpen] = useState<boolean>(false);
  const goToAllCourses = () => {
    if (journeyId) {
      navigate(`/learning-journeys/${journeyId}`);
    } else {
      navigate(`/courses?view=${COURSE_STATES.ALL}`);
    }
  };

  const [courseIsAssigned, setCourseIsAssigned] = useState<boolean>(false);
  const [rateCourseOpen, setRateCourseOpen] = useState<boolean>(false);

  const { isLoading, mutate } = useSelfAssignCourseMutation();
  const feedbackSubmitMutation = useFeedbackSubmitMutation();

  const queryClient = useQueryClient();
  const {
    toastMessages: { courseSelfAssignedSuccessMessage, courseSelfAssignedFailedMessage },
  } = useStrapiHQAdminCoursesData();
  const selfAssignCourseHandler = () => {
    mutate(courseId, {
      onSuccess: () => {
        setCourseIsAssigned(true);
        queryClient.removeQueries({
          queryKey: getCourseModulesQueryKey(`${courseId}`),
        });
        queryClient.removeQueries({
          queryKey: ['courses'],
        });
        queryClient.removeQueries({
          queryKey: ['teams', courseId, 'NOT_ASSIGNED'],
        });
        queryClient.removeQueries({
          queryKey: ['users', courseId, 'NOT_ASSIGNED'],
        });
        if (refetchDetails) refetchDetails();
        continueCourseHandler();
        toast.custom((t) => {
          return (
            <Toast
              variant="success"
              Title=""
              SubTitle={courseSelfAssignedSuccessMessage}
              toastInstance={t}
            />
          );
        });
      },
      onError: (error) => {
        console.error('Error while creating course %o', error);
        toast.custom((t) => (
          <Toast
            variant="error"
            Title=""
            SubTitle={courseSelfAssignedFailedMessage}
            toastInstance={t}
          />
        ));
      },
    });
  };
  const { topNav } = useStrapiCoursesData();

  const continueCourseHandler = () => {
    navigate(`modules`);
  };

  const rateCourseHandler = () => {
    setRateCourseOpen(true);
  };

  const feedbackSubmitHandler = (data: submitFeedbackFormType) => {
    if (id) {
      const payload: submitFeedbackPayloadType = {
        ...data,
        itemId: parseInt(id),
        type: FEEDBACK_TYPE.COURSE,
      };
      feedbackSubmitMutation.mutate(payload, {
        onSuccess: () => {
          setRateCourseOpen(false);
        },
      });
    }
  };

  return (
    <div className="relative flex flex-col justify-between gap-2 py-4 sm:py-8 md:flex-row">
      {/* Background Box */}
      <div
        className={cn(
          'absolute z-[-1] h-[calc(130%+var(--extend-y))] w-full border-b border-zinc-800 bg-zinc-900',
          '[--extend-y:2.5rem] sm:[--extend-y:3.5rem]',
          '-inset-x-[var(--sidebar-base-layout-px)]',
          '-inset-y-[var(--extend-y)]',
          'w-[calc(100%_+_2*var(--sidebar-base-layout-px))]'
        )}
      ></div>
      {/* Left Section */}
      <div className="flex flex-col gap-2">
        <div className="flex w-max cursor-pointer flex-row items-center gap-1 text-sm text-zinc-300">
          <ArrowLeft size="16px" color="white" onClick={goToAllCourses} />
          <p
            className="border-b border-b-transparent hover:border-b-zinc-300"
            onClick={goToAllCourses}
          >
            {journeyId ? topNav.backToLearningJourney : topNav.backButton}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h1 className="text-2xl font-semibold text-white">{title}</h1>
          {isCompleted && (
            <CourseCompletedBadge
              label={topNav.courseCompletedLabel}
              className="flex h-max w-max py-1 pl-2 pr-2.5 font-normal lg:hidden"
            />
          )}
        </div>
      </div>
      {/* Right Section */}
      <div className="my-3 flex h-max flex-row items-center gap-2.5 text-xs md:my-0">
        {isCompleted && (
          <CourseCompletedBadge
            label={topNav.courseCompletedLabel}
            className="hidden h-max font-semibold lg:flex"
          />
        )}
        <HasAccess
          roles={[
            USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
            USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
            USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
          ]}
          Fallback={null}
        >
          <Dialog
            containerClassName="!max-w-3xl"
            open={assignCourseModalOpen}
            onOpenChange={(open) => setAssignCourseModalOpen(open)}
            triggerClassName=" h-max !w-fit flex flex-row gap-1 xxs:gap-2 items-center rounded bg-zinc-800 py-3 px-4 md:px-4"
            Content={
              <AssignCourseModal
                refetchDetails={refetchDetails}
                courseId={courseId}
                modalState={setAssignCourseModalOpen}
              />
            }
            onInteractOutside={(e) => {
              // Donot close the Modal when we click outside the Dialog Content
              e.preventDefault();
            }}
          >
            <Plus size="16px" color="white" />
            <span className="text-xs font-semibold md:whitespace-nowrap">
              {topNav.assignCourseButton}
            </span>
          </Dialog>
        </HasAccess>
        {!courseIsAssigned && !isAssigned && (
          <Button
            onClick={selfAssignCourseHandler}
            className="flex h-max flex-row items-center gap-1 rounded bg-base-brand py-3 px-4 xxs:gap-2"
            contentWrapperClassName="gap-x-2 items-center"
            disabled={isLoading}
          >
            <span className="text-xs font-semibold md:whitespace-nowrap">
              {topNav.startCourseButton}
            </span>
            {!isLoading && <ArrowRight strokeWidth={'1.5'} size="16px" color="white" />}
            {isLoading && <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />}
          </Button>
        )}
        {(isAssigned || isStarted) && !isCompleted && (
          <Button
            onClick={continueCourseHandler}
            className="flex h-max flex-row items-center gap-1 rounded bg-base-brand py-3 px-4 xxs:gap-2"
            contentWrapperClassName="gap-x-2 items-center"
          >
            <span className="text-xs font-semibold md:whitespace-nowrap">
              {topNav.continueCourseButton}
            </span>
            {!isLoading && <ArrowRight strokeWidth={'1.5'} size="16px" color="white" />}
            {isLoading && <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />}
          </Button>
        )}
        {isCompleted && (
          <Button
            onClick={rateCourseHandler}
            className=" flex h-max flex-row items-center gap-1 rounded bg-base-brand py-3 px-4 xxs:gap-2 md:whitespace-nowrap"
          >
            <span className="text-xs font-semibold">{topNav.rateCourseButton}</span>
          </Button>
        )}
        {/* Course Completed Modal */}
        <Dialog
          open={rateCourseOpen}
          onOpenChange={(open) => {
            setRateCourseOpen(open);
          }}
          triggerClassName="flex grow xs:w-fit items-center gap-2 bg-base-brand text-xs font-medium text-white rounded justify-center"
          onInteractOutside={(e) => {
            // Donot close the Modal when we click outside the Dialog Content
            e.preventDefault();
          }}
          Content={
            <RatingModal
              submitFormHandler={feedbackSubmitHandler}
              isSubmitting={feedbackSubmitMutation.isLoading}
            />
          }
        ></Dialog>
      </div>
    </div>
  );
};

export default SingleCourseHeader;
